import {EnvelopeIcon} from '@heroicons/react/24/outline';
import React from "react";
import ContactList from "./ContactList";
import {PopupButton} from "react-calendly";
import {useTranslation} from "react-i18next";

function Contact() {
    const { t } = useTranslation();

    return (
        <div id="contact" className="snap-start overflow-hidden h-screen bg-black font-body">
            <div className={"flex justify-center flex-row"}>
                <div
                    className="flex flex-col h-screen justify-start pt-32 md:pt-40 px-8">
                    <div>
                        <div className="flex gap-6 flex-row justify-center -translate-x-1 items-center mx-auto">
                            <EnvelopeIcon className="h-16 w-16 text-white"/>
                            <h2 className="text-5xl font-display font-semibold uppercase text-white">{t('pages.contact.sentence')}</h2>
                        </div>
                        <div className="flex flex-col mx-auto">
                            <div className="flex flex-col space-y-4 text-base text-neutral-500 sm:space-y-2">
                                <ContactList/>
                            </div>
                            <div
                                className={"text-white flex justify-center text-center bg-red-600 h-10 rounded-full"}>
                                <PopupButton
                                    url="https://calendly.com/gaspare-mascolino/30min"
                                    rootElement={document.getElementById("root")}
                                    text={t('pages.contact.buttonLabel')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Contact;