import React from "react";
import portrait from "../../assets/images/portrait-circle.png";
import './resume-download.css'
import {education, experiences, myName, resumeDownloadSkills, roles} from "../../data/Data";
import ResumeContacts from "./ResumeContacts";
import ResumeSkills from "./ResumeSkills";
import ResumeProfile from "./ResumeProfile";
import ResumeCertifications from "./ResumeCertifications";

function ResumeDownload() {

    return (
        <div id={"resume"}
             className={"font-body cursor-default grid grid-cols-3 h-screen text-black"}>
            <div className="bg-neutral-900 flex flex-col items-center text-white font-body"
                 onLoad={() => window.print()}>
                <img src={portrait} alt={"portrait"} className={"w-48 mx-auto pt-8 pb-6 "}></img>
                <span className={"font-bold text-xl"}>{myName}</span>
                <div className={"w-1/2 h-[0.005rem] mt-4 bg-neutral-500"}></div>
                <span className={"text-neutral-500 font-light text-sm px-2 py-3 md:py-3"}>{roles}</span>
                <div>
                    <ResumeContacts showPhone={true} textSize={'text-xs'}/>
                    <ResumeSkills skills={resumeDownloadSkills}/>
                </div>
                <div className={"h-7"}>
                </div>
            </div>
            <div className="col-span-2 px-10 pt-10 text-[0.7rem]">
                <div className={"pb-4 font-semibold text-2xl"}>PROFILE</div>
                <ResumeProfile/>
                <div className={"pb-6 pt-6 font-semibold text-2xl"}>EXPERIENCE</div>
                {experiences.map((item) => {
                    return (
                        <div key={item.title + item.subtitle} className={"pb-3"}>
                            <div className={"flex flex-row"}>
                                <div className={"flex flex-row"}>
                                    <img src={item.logo} className={"w-12 h-12 mr-2 -z-10 md:mr-3"}
                                         alt={"experience_logo"}/>
                                    <div>
                                        <div className={"flex flex-col"}>
                                            <span className={"font-bold text-sm md:text-base"}>{item.title}</span>
                                            <span className={"text-sm md:text-base"}>{item.subtitle}</span>
                                            <span
                                                className={"text-sm text-neutral-400 md:text-base"}>{item.period}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={"py-3"}>{item.description} </p>
                            <ul className="list-disc list-inside">
                                {item.skills.map((skill) => <li key={skill} className={"py-1"}>{skill}</li>)}
                            </ul>
                            <div className={"py-3"}></div>
                        </div>)
                })}
                <div className={"pt-4 font-semibold text-2xl"}>EDUCATION</div>
                <div className={"flex flex-row text-sm"}>
                    {education.map((item) => {
                        return (<div key={item.title} className={`w-60 h-24 p-3 md:w-96 md:h-28`}>
                            <div className={"flex flex-row pt-3"}>
                                <img src={item.logo} className={"w-12 h-12 mr-2 -z-10 md:mr-3"}
                                     alt={"experience_logo"}/>
                                <div>
                                    <div className={"flex flex-col"}>
                                        <span className={"font-bold text-xs md:text-base"}>{item.title}</span>
                                        <span className={"text-sm md:text-base"}>{item.subtitle}</span>
                                        <span
                                            className={"text-sm text-neutral-400 md:text-base"}>{item.period}</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
                <div className={"w-full h-[0.005rem] mt-6 bg-neutral-500 mb-2"}></div>
                <div className={"pt-4 font-semibold text-2xl"}>CERTIFICATIONS</div>
                <ResumeCertifications/>
                <div className={"w-full h-[0.005rem] mt-3 bg-neutral-500 mb-2"}></div>
                <p className={"text-[0.5rem] text-neutral-500"}>In compliance with the Italian Legislative Decree no.
                    196 dated 30/06/2003, I hereby authorise the recipient of this document to use and process my
                    personal details for the purpose of recruiting and selecting staff and I confirm to be informed of
                    my rights in accordance to art. 7 of the above mentioned decree.</p>
                <div className={"w-full h-[0.005rem] mt-3 bg-neutral-500 mb-2"}></div>
            </div>
        </div>
    )
}

export default ResumeDownload;