import React from "react";
import {scrollToSection, pages} from "../../data/Data";

function Headers(props) {

    return (
        <div id="headers" className="hidden md:flex">
            {pages.map(page => (
                <div
                    key={props.translator(page)}
                    className="font-display font-semibold mr-6 cursor-pointer"
                    onClick={() => scrollToSection(props.translator(page, { lng: 'en' }))}
                >
                    <span className="uppercase hover:text-color-yellow">{props.translator(page)}</span>
                </div>
            ))}
        </div>
    );
}

export default Headers;