import {experiences} from "../../data/Data";
import React from "react";
import Carousel from "./Carousel/Carousel";
import CarouselExperienceItem from "./Carousel/CarouselExperienceItem";

function ResumeExperience() {

    return (
        <Carousel items={experiences} height={"h-56"} carouselItem={CarouselExperienceItem}/>
    )
}

export default ResumeExperience;