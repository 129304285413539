import {certifications} from "../../data/Data";
import React from "react";
import Carousel from "./Carousel/Carousel";
import CarouselCertificationItem from "./Carousel/CarouselCertificationItem";

function ResumeCertifications() {

    return (
        <>
            <Carousel items={certifications} height={"h-36"} carouselItem={CarouselCertificationItem}/>
        </>

    )
}

export default ResumeCertifications;