import React from "react";
import i18n, {changeLanguage} from "i18next";
import FlagIT from "../../assets/icons/flag-it.svg";
import FlagEN from "../../assets/icons/flag-en.svg";

function LanguageSelector() {

    return (
        <div className="fixed language-selector top-5 right-8 w-12 md:left-8 md:top-3 z-50">
            {i18n.language === 'en' && (
                <img
                    src={FlagEN}
                    alt="English"
                    onClick={() => changeLanguage('it')}
                    className="w-12 cursor-pointer mr-2"
                />
            )}
            {i18n.language === 'it' && (
                <img
                    src={FlagIT}
                    alt="Italian"
                    onClick={() => changeLanguage('en')}
                    className="w-12 cursor-pointer"
                />
            )}
        </div>
    );
}

export default LanguageSelector;