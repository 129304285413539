import React, { useRef } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { scrollToSection, pages } from "../../data/Data";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useTranslation } from 'react-i18next';
import './my-sidebar.css';

function MySidebar(props) {
    const sidebarRef = useRef(null);

    useOutsideClick(sidebarRef, props.collapsed, props.toggleSidebar);
    const { t } = useTranslation();

    return (
        <>
            <div
                className={`sidebar-overlay ${!props.collapsed ? 'active' : ''}`}
                onClick={props.toggleSidebar}
            ></div>

            <div id="sidebar" ref={sidebarRef} className={`sidebar-content ${!props.collapsed ? 'sidebar-shadow' : ''} ${props.collapsed ? 'hide' : ''}`}>
                <Sidebar collapsedWidth="0" width="200px" collapsed={props.collapsed} backgroundColor="rgb(255, 255, 255, 1)">
                    <Menu>
                        <div className="flex flex-row font-bold font-display pt-10 pb-5 pl-4">
                            <h2>GASPARE</h2>
                            <h2 className="text-red-600">MASCOLINO</h2>
                        </div>
                        {pages.map((page) => (
                            <MenuItem key={t(page)}>
                                <div
                                    className="font-display mr-6 cursor-pointer"
                                    onClick={() => {
                                        scrollToSection(t(page, { lng: 'en' }));
                                        props.toggleSidebar();
                                    }}
                                >
                                    <span className="uppercase">{t(page)}</span>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </Sidebar>
            </div>
        </>
    );
}

export default MySidebar;
