import React from "react";
import {InfiniteLooper} from "../Home/InfiniteLooper";
import "./portfolio-layout.css";
import {useTranslation} from "react-i18next";

function PortfolioLayout(props) {
    const {t} = useTranslation();

    return (
        <div className={"relative h-[calc(100vh)] snap-start font-display cursor-default"}>
            <div
                className={`absolute w-screen h-screen ${props.backgroundText} leading-[0.85] font-semibold text-[10rem] opacity-5 overflow-hidden`}>
                {(() => {
                    const arr = [];
                    for (let i = 0; i < 6; i++) {
                        arr.push(
                            <InfiniteLooper key={i} speed={10} direction={"right"} children={
                                <span className={"break-all z-0"}>
                                    {props.title.replace(" ", "").slice(i)}
                                </span>}>
                            </InfiniteLooper>
                        );
                    }
                    return arr;
                })()}
            </div>

            <div id={"project"}
                 className={`px-20 ${props.background} h-screen flex flex-col ${props.flexReverse}`}>
                <div
                    className={"h-screen/2 flex md:h-screen items-end md:w-6/12 md:float-left md:flex-col justify-center px-10 float3d"}>
                    {React.createElement(props.image)}
                    {React.createElement(props.image3d)}
                </div>
                <div
                    className={`${props.textColor} h-screen/2 flex flex-col pt-14 z-20 md:pt-0 md:h-screen md:justify-center md:w-6/12 md:float-left`}>
                    <span className={"font-semibold p-3 text-5xl text-center text-[7vw] md:text-[6vw]"}>
                        {props.title}
                    </span>
                    <span
                        className={"text-base font-extralight text-center p-1 md:p-3 md:text-2xl"}>{t(props.description)}
                    </span>
                    <div className={"w-full flex flex-col items-center p-4 md:p-3"}>
                        <a href={props.link} target={"_blank"} rel={"noreferrer"}
                           className={`w-24 h-10 border-2 ${props.borderColor} ${props.textColor} rounded-full flex flex-col justify-center cursor-pointer ${props.hoverColor} ${props.hoverTextColor}`}>
                            <span className={`font-light text-sm text-center`}>{t('pages.portfolio.buttonLabel')}</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PortfolioLayout;