function CarouselCertificationItem(props) {

    return (
        <div className={"text-center"}>
            <div className={`w-12 h-12 flex items-center rounded-full shadow-md p-3 overflow-hidden mb-3 cursor-pointer md:w-20 md:h-20`}>
                <a href={props.subtitle} target={"_blank"} rel="noreferrer" className={"text-center"}>
                    <img src={props.logo} className={""} alt={"angular"}/>
                </a>
            </div>
            <span className={"font-bold"}>{props.title}</span>
        </div>
    )
}

export default CarouselCertificationItem;