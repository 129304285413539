import PortfolioLayout from "../PortfolioLayout";

import AriaImage from "./AriaImage";
import AriaImage3d from "./AriaImage3d";

function PortfolioAriaCouture() {
    return (
        <div id={"portfolio-aria-couture"}>
            <PortfolioLayout
                gradient={"from-white to-red-800"}
                background={"bg-red-800"}
                backgroundText={"text-white"}
                textColor={"text-white"}
                hoverTextColor={"hover:text-red-800"}
                hoverColor={"hover:bg-white"}
                borderColor={"border-white"}
                title={"ARIA COUTURE"}
                link={"https://aria-couture.com/"}
                image={AriaImage}
                image3d={AriaImage3d}
                description={'pages.portfolio.projects.ariaCouture.description'}
                flexReverse={"md:flex-row"}/>
        </div>
    )
}

export default PortfolioAriaCouture;