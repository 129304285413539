import React from "react";


function PortfolioHeader() {
    return (
        <div
            className={"h-[calc(100vh-5rem)] sticky z-20 font-semibold text-3xl text-center text-white mix-blend-lighten w-full pt-12 top-0 md:text-5xl md:text-left md:top-20 md:left-24 md:pt-0 md:w-fit"}>
            PORTFOLIO
        </div>
    )
}

export default PortfolioHeader;