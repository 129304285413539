import React from "react";
import Burger from "./Burger";
import Headers from "./Headers";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

function Header(props) {
    const { t } = useTranslation();

    return (
        <nav>
            <div className="fixed flex text-sm z-40 text-white w-screen p-6 md:justify-end mix-blend-lighten">
                <Burger toggleSidebar={props.toggleSidebar} />
                <Headers translator={t} />
            </div>
            <LanguageSelector/>
        </nav>
    );
}

export default Header;