import React, {useState} from 'react';
import './index.css';
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import MySidebar from "./components/Sidebar/MySidebar";
import Contact from "./pages/Contact/Contact";
import portrait from "./assets/images/portrait-circle.png";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import {HashRouter, Route, Routes} from "react-router-dom";
import ResumeDownload from "./pages/Resume/ResumeDownload";
import {useTranslation} from "react-i18next";

function App() {
    const [collapseSidebar, setCollapseSidebar] = useState(true);
    const { t } = useTranslation();

    function toggleSidebar() {
        setCollapseSidebar(!collapseSidebar);
    }

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={
                    <div className={"h-screen w-screen flex overflow-x-hidden relative"}>
                        <MySidebar collapsed={collapseSidebar} toggleSidebar={toggleSidebar}/>
                        <div id={"sections"} className={"h-screen min-w-[100vw] overflow-auto snap-y snap-mandatory"}>
                            <Header collapsed={collapseSidebar} toggleSidebar={toggleSidebar}/>
                            <Home/>
                            <Portfolio/>
                            <Contact/>
                            <FloatingWhatsApp
                                phoneNumber={393387292808}
                                accountName={"Gaspare Mascolino"}
                                statusMessage={t('whatsapp.statusMessage')}
                                avatar={portrait}
                                chatMessage={t('whatsapp.chatMessage')}
                            />
                        </div>
                    </div>
                }>
                </Route>
                <Route path="resume" element={<ResumeDownload/>}/>
            </Routes>
        </HashRouter>
    );
}

export default App;
