import React from "react";

function Burger(props) {

    return (
        <div id="burger" className="md:hidden cursor-pointer">
            <div className="flex flex-col text-md font-bold leading-[0.6]" onClick={props.toggleSidebar}>
                <span>___</span>
                <span>___</span>
                <span>___</span>
            </div>
        </div>
    );
}

export default Burger;