import React from "react";
import Typewriter from "typewriter-effect";
import Socials from "./Socials";
import DownArrowIcon from "../../components/Icons/DownArrowIcon";
import { dynamic_sentences } from "../../data/Data";
import { useTranslation } from "react-i18next";

function Home() {
    const { t } = useTranslation();

    return (
        <div id={"home"} className={"snap-start overflow-hidden"}>
            <div className={"h-screen flex justify-center flex-col lg:h-screen lg:py-5"}>
                <div className={"flex flex-col min-h-0 text-center font-display font-semibold text-5xl lg:text-9xl"}>
                    <p>GASPARE</p>
                    <p className={"text-red-600"}>MASCOLINO</p>
                    <div className={"font-body font-normal text-xl py-7 sm:py-10"}>
                        <Typewriter options={{ strings: dynamic_sentences.map((sentence) => t(sentence)), autoStart: true, loop: true }} />
                    </div>
                    <div className="flex gap-x-4 justify-center">
                        <Socials />
                    </div>
                    <div className={"flex justify-center pt-10 transition duration-300 delay-150 animate-bounce -z-10 lg:pt-14"}>
                        <DownArrowIcon />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;