import PortfolioValentinaTioli from "./ValentinaTioli/PortfolioValentinaTioli";
import React from "react";
import PortfolioHeader from "./PortfolioHeader";
import PortfolioAriaCouture from "./AriaCouture/PortfolioAriaCouture";

function Resume() {
    return (
        <div id={"portfolio"} className={"font-display cursor-default"}>
            <PortfolioHeader/>
            <PortfolioAriaCouture/>
            <PortfolioValentinaTioli/>
        </div>
    )
}

export default Resume;