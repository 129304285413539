import PortfolioLayout from "../PortfolioLayout";
import ValentinaTioliImage from "./ValentinaTioliImage";
import ValentinaTioliImage3d from "./ValentinaTioliImage3d";


function PortfolioValentinaTioli() {
    return (
        <div id={"portfolio-valentina-tioli"}>
            <PortfolioLayout
                background={"bg-[#70999f]"}
                backgroundText={"text-white"}
                textColor={"text-white"}
                hoverTextColor={"hover:text-black"}
                hoverColor={"hover:bg-white"}
                borderColor={"border-white"}
                title={"VALENTINA TIOLI"}
                link={"https://valentinatioli.com/"}
                image={ValentinaTioliImage}
                image3d={ValentinaTioliImage3d}
                description={'pages.portfolio.projects.valentinaTioli.description'}
                flexReverse={"md:flex-row-reverse"}/>
        </div>
    )
}

export default PortfolioValentinaTioli;