import {contacts} from "../../data/Data";
import React from "react";
import {useTranslation} from "react-i18next";

function ContactList() {

    const { t } = useTranslation();


    return (
        <div className={"py-6 flex flex-col"}>
            {contacts.map(({label, Icon, href, value}) => (
                    <div key={label} className={"inline-flex items-center py-2 cursor-pointer"}>
                        <Icon className="h-7 w-7 align-baseline fill-white"></Icon>
                        <a className={"text-white px-3 text-sm hover:text-neutral-300 underline-offset-4"}
                           href={href} target="_blank" rel="noreferrer">{t(value)}</a>
                    </div>
                )
            )}
        </div>
    )
}

export default ContactList;